import { getOpenAPIDefinition } from '../api/client'
import axios, { AxiosRequestConfig, AxiosResponse, isAxiosError } from 'axios'
import {
  AccountDto,
  LockedStatus,
  OrganizationDto,
  OrganizationListItemDto,
  OrganizationViewDto,
  UserDto,
  TaskDto,
  UserListItemDto,
  ProductionDeviceListViewDto,
  UserViewDto,
  ProductionInfoTransferDto,
  NotificationDto,
  ProductionDeviceReportViewDto,
  ProductionDeviceLabelReportDto,
  ApplicationConfigurationDto,
  AccountDetailsDto,
  CountryView,
  AibMemberDto,
  PasswordChangeDto,
  TransactionDto,
  TransactionWithFullDetailsDto,
  TransferDomesticTransactionDto,
  TransferPersonalTransactionDto,
  TransferExternalTransactionDto,
  TransferResult,
  AutomaticTransferDto,
  GetProductionDevicesManagedByOrganizationDuringParams,
  AdditionalInvoiceRowsDto,
  CancellationTransactionDto,
  CorrectionTransactionDto,
  CertificateBundleDto,
  ExternalTransferQuery,
  ResultPageExternalTransferReportItemDto,
  CancellationDetailsUpdateDto,
  AccountUpdateDto,
  LogEntryQuery,
  ResultPageLogEntryDto,
  AccountSelectItemDto,
  TransactionQuery,
  TransactionQueryResult,
  ProductionInfoTransferDetailsDto,
  UserSettingDto,
  UserEditDto,
  FuelDistributionDto,
  ProductionDeviceViewDto,
  ProductionDeviceManagementHistoryView,
  SetNewProductionDeviceManagerBody,
  UpdateProductionDeviceManagerBody,
  PageQuery,
  ProductionDeviceUpdateEventDto,
  ProductionDeviceDto,
  UploadFuelDistributionBody,
  ResultPageNotificationViewDto,
  NotificationViewDto
} from '../api/types'

export enum ApiErrorType {
  CONNECTION = 'CONNECTION',
  AUTHENTICATION = 'AUTHENTICATION',
  AUTHORIZATION = 'AUTHORIZATION',
  OTHER_CLIENT = 'OTHER_CLIENT',
  OTHER_SERVER = 'OTHER_SERVER',
  BAD_REQUEST = 'BAD_REQUEST',
  UNKNOWN = 'UNKNOWN',
  NOT_FOUND = 'NOT_FOUND'
}

export type ProductionDeviceBody = {
  organizationId: number
  dto: ProductionDeviceDto
  poa?: Blob
  files?: Blob[]
}

declare module 'axios' {
  export interface AxiosRequestConfig {
    disableGlobalClientErrorNotifications?: boolean
  }
}

axios.interceptors.response.use(
  (response) => {
    return response
  },
  (error) => {
    if (isAxiosError(error)) {
      const errorType = (() => {
        switch (error.code) {
          case 'ERR_NETWORK':
            return ApiErrorType.CONNECTION
          case 'ECONNABORTED':
            return ApiErrorType.CONNECTION
          case 'ERR_BAD_REQUEST':
            if (error.config?.disableGlobalClientErrorNotifications) {
              return null
            }
            switch (error.response?.status) {
              case 400:
                return ApiErrorType.BAD_REQUEST
              case 401:
                return ApiErrorType.AUTHENTICATION
              case 403:
                return ApiErrorType.AUTHORIZATION
              default:
                return ApiErrorType.OTHER_CLIENT
            }
          case 'ERR_BAD_RESPONSE':
            return ApiErrorType.OTHER_SERVER
          default:
            return ApiErrorType.UNKNOWN
        }
      })()
      if (errorType) {
        window.dispatchEvent(new CustomEvent('api-error', { detail: { error: errorType, response: error } }))
      }
    }
    throw error
  }
)

const api = getOpenAPIDefinition()

export namespace ECERT_API {
  const headers = {
    contentTypeText: { 'Content-Type': 'text/plain' },
    contentTypeUndefined: { 'Content-Type': undefined }
  }

  export const FILE_DOWNLOAD_URLS = {
    USER_LIST: '/api/user/user-export/locked/',
    PRODUCTION_DEVICES: (orgId: number) => `/api/organization/${orgId}/production-device-export?downloadId=`,
    MANUAL: (lang: string) => `/api/manual?language=${lang}`,
    CORRECTION_INFO: (lang: string) => `/api/correction-info?language=${lang}`,
    ACCESSIBILITY_STATEMENT: '/api/accessibility-statement',
    REGISTERED_PRODUCTION_DEVICES: '/api/production_device/report-export?downloadId=',
    LABEL_REPORT: '/api/production_device/type/label/report-export?downloadId=',
    DOWNLOAD_CONTACTS: '/api/organization/contacts-export?downloadId=',
    DOWNLOAD_INVOIVING_PERIOD: `/api/invoice/export/period/`,
    DOWNLOAD_INVOICING_PERIOD_ALL: (periodId: number) => `/api/invoice/export/period/${periodId}/zip`,
    TRANSACTION_STATEMENT: (organizationId: string, accountId: string, transactionId: string) =>
      `/api/organization/${organizationId}/account/${accountId}/transaction/${transactionId}/export`,
    DOWNLOAD_ISSUING_REPORT: `/api/organization/`,
    DOWNLOAD_FUEL_CORRESPONDENCE: `/api/organization/production_device/fuel_guide`,
    EXTERNAL_TRANSFER: (transactionId: string) => `/api/external-transfer/${transactionId}/transfer-xml`,
    TRANSACTION_REPORT: (
      organizationId: number,
      accountId: number,
      fromTime: string,
      toTime: string,
      page: number,
      transactionTypes: string,
      withCertificates: boolean
    ) =>
      `/api/organization/${organizationId}/transaction-export?accountId=${accountId}&fromTime=${fromTime}&toTime=${toTime}&page=${page}&${transactionTypes}&withCertificates=${withCertificates}&downloadId=`,
    FUEL_DISTRIBUTION_TEMPLATE: (organizationId: number, productionDeviceId: number, year: number) =>
      `/api/organization/${organizationId}/production_device/${productionDeviceId}/fuel-distribution-template/${year}`,
    FUEL_GUIDE: `/api/organization/production_device/fuel_guide`,
    PRODUCTION_DEVICE_ATTACHMENT: (orgId: number, deviceId: number, attachmentId: number) =>
      `/api/organization/${orgId}/production_device/${deviceId}/attachment/${attachmentId}`,
    SET_NEW_MANAGER: (orgId: number, deviceId: number) =>
      `/api/organization/${orgId}/production_device/${deviceId}/manager`,
    UPDATE_CURRENT_MANAGER: (orgId: number, deviceId: number) =>
      `/api/organization/${orgId}/production_device/${deviceId}/manager`,
    UPDATE_MANAGER: (orgId: number, deviceId: number, managerId: number) =>
      `/api/organization/${orgId}/production_device/${deviceId}/manager/${managerId}`,
    ACCOUNT_EXPORT: (orgId: number) => `/api/organization/${orgId}/account/export?downloadId=`,
    ACCOUNT_EXPORT_ALL: () => `/api/organization/account/export/all?downloadId=`,
    UPLOAD_FUEL_DISTRIBUTION_EXCEL: (year: number) => `/api/upload-fuel-distribution-excel/${year}`,
    SIGNED_DOCUMENTS: (organizationId: number | null, esignOperationId: number) =>
      `/api/signed-document-export/organization/${organizationId ?? 0}/esign/${esignOperationId}?downloadId=`,
    SIGNED_DOCUMENTS_BY_BENEFICIARY_NAME: (
      organizationId: number | null,
      consumptionPeriodStart: string | undefined,
      consumptionPeriodEnd: string | undefined,
      beneficiaryName: string | null
    ) =>
      `/api/signed-document-export/organization/${organizationId ?? 0}?consumptionPeriodStart=${
        consumptionPeriodStart ?? ''
      }&consumptionPeriodEnd=${consumptionPeriodEnd ?? ''}&beneficiaryName=${beneficiaryName ?? ''}`
  }

  export const FILE_URLS = {
    PRIVACY_STATEMENT_FI:
      'https://www.fingrid.fi/globalassets/dokumentit/fi/tietosuojaselosteet/sahkomarkkinalainsaadannon-mukaisten-tehtavien-tietosuojaseloste-fi.pdf',
    PRIVACY_STATEMENT_EN:
      'https://www.fingrid.fi/globalassets/dokumentit/fi/tietosuojaselosteet/sahkomarkkinalainsaadannon-mukaisten-tehtavien-tietosuojaseloste-eng2.pdf',
    PRIVACY_STATEMENT_SV:
      'https://www.fingrid.fi/globalassets/dokumentit/fi/tietosuojaselosteet/sahkomarkkinalainsaadannon-mukaisten-tehtavien-tietosuojaseloste-sv2.pdf'
  }

  const createProductionDeviceManagerFormData = (
    body: UpdateProductionDeviceManagerBody | SetNewProductionDeviceManagerBody
  ) => {
    const fd: FormData = new FormData()

    if (body.poa) {
      fd.append('poa', body.poa)
    }

    body.otherFiles?.forEach((file) => {
      fd.append('otherFiles', file)
    })

    fd.append(
      'command',
      new Blob([JSON.stringify(body.command)], {
        type: 'application/json'
      })
    )

    return fd
  }

  export const loadSessionUser = () => {
    return api.getLoggedInUser({ disableGlobalClientErrorNotifications: true })
  }

  export const login = (username: string, password: string) => {
    return axios.post(
      '/api/user/login',
      `username=${encodeURIComponent(username)}&password=${encodeURIComponent(password)}`,
      { disableGlobalClientErrorNotifications: true }
    )
  }

  export const verifyLogin = (pin: string) => {
    return api.verifyLoggedInUser({ pin }, { disableGlobalClientErrorNotifications: true })
  }

  export const logout = () => {
    return axios.post('/api/user/logout', undefined, { disableGlobalClientErrorNotifications: true })
  }

  export const getAllOrganizations = (): Promise<AxiosResponse<OrganizationListItemDto[]>> => {
    return api.getAll()
  }

  export const createOrganization = (organization: OrganizationDto): Promise<AxiosResponse<OrganizationDto>> => {
    return api.add(organization)
  }

  export const getOrganization = (organizationId: number): Promise<AxiosResponse<OrganizationViewDto>> => {
    return api.get(organizationId)
  }

  export const updateOrganization = (
    organizationId: number,
    organizationDto: OrganizationDto
  ): Promise<AxiosResponse<OrganizationViewDto>> => {
    return api.update(organizationId, organizationDto)
  }

  export const lockOrganization = (organizationId: number, comment: string): Promise<AxiosResponse<LockedStatus>> => {
    return api.changeLockedState2(organizationId, comment, {
      headers: headers.contentTypeText
    })
  }

  export const unlockOrganization = (organizationId: number): Promise<AxiosResponse<LockedStatus>> => {
    return api.changeLockedState2(organizationId, '', {
      headers: headers.contentTypeText
    })
  }

  export const getOrganizationUsers = (organizationId: number): Promise<AxiosResponse<UserListItemDto[]>> => {
    return api.getUsers(organizationId)
  }

  export const updateOrganizationMainUsers = (organizationId: number, mainUserIds: number[]) => {
    return api.updateOrganizationMainUsers(organizationId, mainUserIds)
  }

  export const getTasks = (): Promise<AxiosResponse<TaskDto>> => {
    return api.getTasks()
  }

  export const getAllAccounts = (): Promise<AxiosResponse<AccountDto[]>> => {
    return api.getAllAccounts()
  }

  export const getAccounts = (organizationId: number): Promise<AxiosResponse<AccountDto[]>> => {
    return api.getAccounts(organizationId)
  }

  export const createAccount = (organizationId: number, account: AccountDto): Promise<AxiosResponse<AccountDto>> => {
    return api.addAccount(organizationId, account)
  }

  export const getAllUsers = (): Promise<AxiosResponse<UserListItemDto[]>> => {
    return api.getAllUsers()
  }

  export const getUser = (organizationId: number, userId: number): Promise<AxiosResponse<UserViewDto>> => {
    return api.getUser(organizationId, userId)
  }

  export const createUser = (organizationId: number, userDto: UserDto): Promise<AxiosResponse<UserDto>> => {
    return api.addUser(organizationId, userDto)
  }

  export const editUser = (organizationId: number, userEditDto: UserEditDto) => {
    return api.updateUser(organizationId, userEditDto)
  }

  export const editLoggedInUser = (userEditDto: UserEditDto) => {
    return api.updateLoggedInUser(userEditDto)
  }

  export const saveCurrentUserSettings = (userSettingsDto: UserSettingDto) => {
    return api.updateSettings(userSettingsDto)
  }

  export const saveUserSettings = (organizationId: number, userId: number, userSettingDto: UserSettingDto) => {
    return api.updateSettings1(organizationId, userId, userSettingDto)
  }

  export const getProductionDevices = (): Promise<AxiosResponse<ProductionDeviceListViewDto[]>> => {
    return api.getAllProductionDevices()
  }

  export const getOrganizationProductionDevices = (
    organizationId: number
  ): Promise<AxiosResponse<ProductionDeviceListViewDto[]>> => {
    return api.getProductionDevices(organizationId)
  }

  export const getAccountsForOrganization = (organizationId: number): Promise<AxiosResponse<AccountDto[]>> => {
    return api.getAccounts(organizationId)
  }

  export const getProductionInfoTransfers = (): Promise<AxiosResponse<ProductionInfoTransferDto[]>> => {
    return api.getProductionInfoTransfers()
  }

  export const getProductionInfoTransfer = (
    productionInfoTransferId: number
  ): Promise<AxiosResponse<ProductionInfoTransferDetailsDto>> => {
    return api.getProductionInfoTransfer(productionInfoTransferId)
  }

  export const updateProductionInfoTransferErrors = (productionInfoTransferId: number) => {
    return api.updateProductionInfoTransferErrors(productionInfoTransferId)
  }

  export const markProductionInfoTransferProcessed = (productionInfoTransferId: number) => {
    return api.markProductionInfoTransferProcessed(productionInfoTransferId)
  }

  export const removeProductionInfoTransfer = (productionInfoTransferId: number) => {
    return api.removeProductionInfoTransfer(productionInfoTransferId)
  }

  export const issueCertificates = (productionInfoTransferId: number) => {
    return api.issueCertificates(productionInfoTransferId)
  }

  export const updateUnprocessedProductionInfoNotifications = () => {
    return api.updateUnprocessedProductionInfoNotifications()
  }

  export const getNotification = (notificationId: number): Promise<AxiosResponse<NotificationDto>> => {
    return api.getNotification(notificationId)
  }

  export const getAllNotifications = (): Promise<AxiosResponse<NotificationDto[]>> => {
    return api.getAllNotifications()
  }

  export const getActiveNotifications = (): Promise<AxiosResponse<NotificationViewDto[]>> => {
    return api.getActiveNotifications()
  }

  export const getArchivedNotifications = (
    pageQuery: PageQuery
  ): Promise<AxiosResponse<ResultPageNotificationViewDto>> => {
    return api.getArchivedNotifications(pageQuery)
  }

  export const acknowledgeExternalTransfer = (transferId: number): Promise<AxiosResponse<void>> => {
    return api.acknowledgeExternalTransfer(transferId)
  }

  export const rollbackExternalTransfer = (transferId: number): Promise<AxiosResponse<void>> => {
    return api.rollbackExternalTransfer(transferId)
  }

  export const getRegisteredProductionDevices = (): Promise<AxiosResponse<ProductionDeviceReportViewDto[]>> => {
    return api.getProductionDeviceReport()
  }

  export const getLabelTags = (): Promise<AxiosResponse<ProductionDeviceLabelReportDto[]>> => {
    return api.getLabelReport()
  }

  export const downloadFile = (fileUrl: string) => {
    return axios({
      url: fileUrl,
      method: 'GET',
      responseType: 'blob'
    })
  }

  export const createNotification = (notification: NotificationDto) => {
    return api.createNotification(notification)
  }

  export const updateNotification = (notificationId: number, notification: NotificationDto) => {
    return api.updateNotification(notificationId, notification)
  }

  export const deleteNotification = (notificationId: number) => {
    return api.deleteNotification(notificationId)
  }

  export const getConfigurations = (): Promise<AxiosResponse<ApplicationConfigurationDto[]>> => {
    return api.getConfigurations()
  }

  export const setConfigurations = (configurations: ApplicationConfigurationDto[]) => {
    return api.setConfiguration(configurations)
  }

  export const getOrganizationContacts = () => {
    return api.getOrganizationContacts()
  }

  export const getInvoicingPeriods = () => {
    return api.getInvoicingPeriods()
  }

  export const getInvoicingPeriod = (id: number) => {
    return api.getInvoicingPeriod(id)
  }

  export const getAibMembers = () => {
    return api.getAibMembers()
  }

  export const getOrganizationAccount = (
    organizationId: number,
    accountId: number
  ): Promise<AxiosResponse<AccountDetailsDto>> => {
    return api.getAccount(organizationId, accountId)
  }

  export const updateAccount = (organizationId: number, accountId: number, accountUpdateDto: AccountUpdateDto) => {
    return api.updateAccount(organizationId, accountId, accountUpdateDto)
  }

  export const lockAccount = (organizationId: number, accountId: number, changeLockedState3Body: string) => {
    return api.changeLockedState3(organizationId, accountId, changeLockedState3Body, {
      headers: headers.contentTypeText
    })
  }

  export const unlockAccount = (organizationId: number, accountId: number) => {
    return api.changeLockedState3(organizationId, accountId, '', {
      headers: headers.contentTypeText
    })
  }

  export const getTransactionsForAccount = (
    organizationId: number,
    accountId: number
  ): Promise<AxiosResponse<TransactionDto[]>> => {
    return api.getTransactionsForAccount(organizationId, accountId)
  }

  export const getTransaction = (
    organizationId: number,
    accountId: number,
    transactionId: number
  ): Promise<AxiosResponse<TransactionWithFullDetailsDto>> => {
    return api.getTransaction(organizationId, accountId, transactionId)
  }

  export const getTransactionRemainingBundles = (
    organizationId: number,
    accountId: number,
    transactionId: number
  ): Promise<AxiosResponse<CertificateBundleDto[]>> => {
    return api.getTransactionBundlesRemainingOnAccount(organizationId, accountId, transactionId)
  }

  export const rollbackCancellation = (transactionId: number, additionalDetails: string) => {
    return api.rollbackCancellation(transactionId, { additionalDetails })
  }

  export const getCountries = (): Promise<AxiosResponse<CountryView[]>> => {
    return api.getCountries()
  }

  export const getCancellationCountries = (): Promise<AxiosResponse<CountryView[]>> => {
    return api.getCancellationCountries()
  }

  export const getAibHubCountries = (): Promise<AxiosResponse<CountryView[]>> => {
    return api.getAibHubCountries()
  }

  export const setAibMembers = (aibMembers: AibMemberDto[]) => {
    return api.setAibMembers(aibMembers)
  }

  export const forgotPassword = (email: string) => {
    return api.forgotPassword(email, { headers: headers.contentTypeText })
  }

  export const getAppInfo = () => {
    return axios({
      url: '/manage/info',
      method: 'GET'
    })
  }

  export const changePassword = (passwordChangeDto: PasswordChangeDto) => {
    return api.updateLoggedInUserPassword(passwordChangeDto)
  }

  export const getPublicAccounts = () => {
    return api.getAllPublicAccounts()
  }

  export const getOrganizationAccounts = (organizationId: number): Promise<AxiosResponse<AccountSelectItemDto[]>> => {
    return api.getOrganizationAccounts(organizationId)
  }

  export const getExternalAccounts = () => {
    return api.getAllExternalAccounts()
  }

  export const transferDomestic = (
    organizationId: number,
    accountId: number,
    transferDomesticDto: TransferDomesticTransactionDto
  ): Promise<AxiosResponse<TransferResult>> => {
    return api.transferDomesticCertificates(organizationId, accountId, transferDomesticDto)
  }

  export const transferOrganization = (
    organizationId: number,
    accountId: number,
    transferOrganizationDto: TransferPersonalTransactionDto
  ): Promise<AxiosResponse<TransferResult>> => {
    return api.transferOrganizationCertificates(organizationId, accountId, transferOrganizationDto)
  }

  export const transferExternal = (
    organizationId: number,
    accountId: number,
    transferExternalDto: TransferExternalTransactionDto
  ): Promise<AxiosResponse<TransferResult>> => {
    return api.transferExternalCertificates(organizationId, accountId, transferExternalDto)
  }

  export const getAutomaticTransfers = (
    organizationId: number,
    accountId: number
  ): Promise<AxiosResponse<AutomaticTransferDto>> => {
    return api.getAutomaticTransfer(organizationId, accountId)
  }

  export const updateAutomaticTransfer = (
    organizationId: number,
    accountId: number,
    automaticTransferDto: AutomaticTransferDto
  ) => {
    return api.updateAutomaticTransfer(organizationId, accountId, automaticTransferDto)
  }

  export const cancelCertificates = (
    organizationId: number,
    accountId: number,
    cancellationTransactionDto: CancellationTransactionDto
  ) => {
    return api.cancelCertificates(organizationId, accountId, cancellationTransactionDto)
  }

  export const updateCancellationDetails = (
    transactionId: number,
    cancellationDetailsUpdateDto: CancellationDetailsUpdateDto
  ) => {
    return api.updateCancellationDetails(transactionId, cancellationDetailsUpdateDto)
  }

  export const correctCertificates = (
    organizationId: number,
    accountId: number,
    correctionTransactionDto: CorrectionTransactionDto
  ) => {
    return api.correctCertificates(organizationId, accountId, correctionTransactionDto)
  }

  type OrganizationType = 'ADMIN' | 'ACCOUNT' | 'INSPECTOR'
  export const getOrganizationsByType = (type: OrganizationType) => {
    return api.getOrganizatinosByType(type)
  }

  export const getInvoiceDetails = (id: number) => {
    return api.getInvoiceDetails(id)
  }

  export const getFeeTypes = () => {
    return api.getFeeTypes()
  }

  export const getProductionDevicesManagedByOrganizationDuring = (
    orgId: number,
    params: GetProductionDevicesManagedByOrganizationDuringParams | undefined
  ) => {
    return api.getProductionDevicesManagedByOrganizationDuring(orgId, params)
  }

  export const saveAdditionalInvoiceRows = <TData = AxiosResponse<void>>(
    id: number,
    data: { id: number; invoiceRows: AdditionalInvoiceRowsDto },
    options?: AxiosRequestConfig
  ): Promise<TData> => {
    return axios.post(`/api/invoice/details/${id}`, data, {
      ...options
    })
  }

  export const getCombinedInvoiceDetails = (id: number) => {
    return api.getCombinedInvoiceDetails(id)
  }

  export const updateInvoiceDetailsMessage = (id: number, message: string) => {
    return api.updateInvoiceDetailsMessage(id, message)
  }

  export const getIssuingReport = (organizationId: number = 172, year: number = 2022) => {
    return api.getIssuingReport5(organizationId, year)
  }

  export const getCodeGenerators = () => {
    return api.getCodeGenerators()
  }

  export const getOwnerParticipants = () => {
    return api.getOwnerParticipants()
  }

  export const getBeneficiaryParticipants = () => {
    return api.getBeneficiaryParticipants()
  }

  export const getAllPublicAccounts = () => {
    return api.getAllPublicAccounts()
  }

  export const getAllEenrgySourceTypes = () => {
    return api.getAllEenrgySourceTypes()
  }

  export const getAllInstallationTypes = () => {
    return api.getAllInstallationTypes()
  }

  export const getProductionDeviceInvestmentTypes = () => {
    return api.getProductionDeviceInvestmentTypes()
  }

  export const getAllProductionDeviceProductionSupportTypes = () => {
    return api.getAllProductionDeviceProductionSupportTypes()
  }

  export const getAllLabelTypesCodes = () => {
    return api.getAllLabelTypesCodes()
  }

  export const getAllowedFuelsByTechnologyType = (type: string) => {
    return api.getAllowedFuelsByTechnologyType(type)
  }

  export const searchExternalTransfer = (
    externalTransferQuery: ExternalTransferQuery,
    orgId: number
  ): Promise<AxiosResponse<ResultPageExternalTransferReportItemDto>> => {
    return api.searchExternalTransfer(orgId, externalTransferQuery)
  }

  export const searchEventLog = (
    organizationId: number,
    logEntryQuery: LogEntryQuery
  ): Promise<AxiosResponse<ResultPageLogEntryDto>> => {
    return api.getLogEntries(organizationId, logEntryQuery)
  }

  export const searchTransactions = (
    organizationId: number,
    transactionQuery: TransactionQuery
  ): Promise<AxiosResponse<TransactionQueryResult>> => {
    return api.searchTransactions(organizationId, transactionQuery)
  }

  export const getAIBCountryCancellationsAllowed = (): Promise<AxiosResponse<boolean>> => {
    return api.isCancellationsToAibCountriesAllowed()
  }

  export const getCurrentProductionDevice = (
    orgId: number,
    deviceId: number
  ): Promise<AxiosResponse<ProductionDeviceViewDto>> => {
    return api.getCurrentProductionDevice(orgId, deviceId)
  }

  export const getProductionDeviceManagementHistory = (
    organizationId: number,
    deviceId: number
  ): Promise<AxiosResponse<ProductionDeviceManagementHistoryView>> => {
    return api.getProductionDeviceManagementHistory(organizationId, deviceId)
  }

  export const setNewProductionDeviceManager = (
    organizationId: number,
    deviceId: number,
    setNewProductionDeviceManagerBody: SetNewProductionDeviceManagerBody
  ) => {
    const fd: FormData = createProductionDeviceManagerFormData(setNewProductionDeviceManagerBody)
    const config = { headers: headers.contentTypeUndefined }
    return axios.post(FILE_DOWNLOAD_URLS.SET_NEW_MANAGER(organizationId, deviceId), fd, config)
  }

  export const updateCurrentProductionDeviceManager = (
    organizationId: number,
    deviceId: number,
    updateProductionDeviceManagerBody: UpdateProductionDeviceManagerBody
  ) => {
    const fd: FormData = createProductionDeviceManagerFormData(updateProductionDeviceManagerBody)
    const config = { headers: headers.contentTypeUndefined }
    return axios.put(FILE_DOWNLOAD_URLS.UPDATE_CURRENT_MANAGER(organizationId, deviceId), fd, config)
  }

  export const updateProductionDeviceManager = (
    organizationId: number,
    deviceId: number,
    managerId: number,
    updateProductionDeviceManagerBody: UpdateProductionDeviceManagerBody
  ) => {
    const fd: FormData = createProductionDeviceManagerFormData(updateProductionDeviceManagerBody)
    const config = { headers: headers.contentTypeUndefined }
    return axios.put(FILE_DOWNLOAD_URLS.UPDATE_MANAGER(organizationId, deviceId, managerId), fd, config)
  }

  export const getFuelDistribution = (
    organizationId: number,
    deviceId: number,
    year: number
  ): Promise<AxiosResponse<FuelDistributionDto>> => {
    return api.getFuelDistributions(organizationId, deviceId, year)
  }

  export const updateFuelDistributions = (
    organizationId: number,
    deviceId,
    fuelDistributionDto: FuelDistributionDto
  ) => {
    return api.updateFuelDistributions(organizationId, deviceId, fuelDistributionDto)
  }

  export const uploadFuelDistributionExcel = (year: number, excel: UploadFuelDistributionBody) => {
    const fd: FormData = new FormData()
    fd.append('file', excel.file)
    return axios.post(FILE_DOWNLOAD_URLS.UPLOAD_FUEL_DISTRIBUTION_EXCEL(year), fd)
  }

  export const lockProductionDevice = (orgId, deviceId, message: string) => {
    return api.changeLockedState1(orgId, deviceId, message, {
      headers: headers.contentTypeText
    })
  }

  export const unlockProductionDevice = (orgId, deviceId) => {
    return api.changeLockedState1(orgId, deviceId, '', {
      headers: headers.contentTypeText
    })
  }

  export const lockUser = (orgId: number, userId: number, message: string) => {
    return api.changeLockedState(orgId, userId, message, {
      headers: headers.contentTypeText
    })
  }

  export const unlockUser = (orgId: number, userId: number) => {
    return api.changeLockedState(orgId, userId, '', {
      headers: headers.contentTypeText
    })
  }

  export const updateLoggedInUserPassword = (passwordChangeDto: PasswordChangeDto) => {
    return api.updateLoggedInUserPassword(passwordChangeDto)
  }

  export const acknowledgeProductionInfoWarnings = (productionInfoId: number) => {
    return api.acknowledgeProductionInfoWarnings(productionInfoId)
  }

  export const unacknowledgeProductionInfoNotification = (productionInfoId: number) => {
    return api.unacknowledgeProductionInfoNotification(productionInfoId)
  }

  export const markNotificationReadForCurrentUser = (notificationId: number) => {
    return api.markNotificationRead(notificationId)
  }

  export const updateProductionDeviceStatus = (
    organizationId: number,
    deviceId: number,
    productionDeviceStatus: ProductionDeviceUpdateEventDto
  ) => {
    return api.updateProductionDeviceStatus(organizationId, deviceId, productionDeviceStatus)
  }

  export const getProductionDeviceDraft = (orgId: number, deviceId: number) => {
    return api.getDraftProductionDevice(orgId, deviceId)
  }

  export const deleteProductionDeviceDraft = (orgId: number, deviceId: number) => {
    return api.deleteProductionDeviceDraft(orgId, deviceId)
  }

  export const publishInvoicingPeriod = (invoicePeriodId: number) => {
    return api.publishInvoicingPeriod(invoicePeriodId)
  }

  export const exportInvoicingPeriodForBilling = (invoicePeriodId: number) => {
    return api.exportInvoicingPeriodForBilling(invoicePeriodId)
  }

  export const updateRemovedStatus = (transferId: number, productionDeviceId: number, removed: boolean) => {
    return api.updateRemovedStatus(transferId, productionDeviceId, { removed })
  }

  export const createDraftProductionDevice = (orgId: number, submit: boolean, data: ProductionDeviceBody) => {
    return postProductionDeviceData(`/api/organization/${orgId}/production_device_draft`, submit, data)
  }

  export const updateDraftProductionDevice = (
    orgId: number,
    deviceId: number,
    submit: boolean,
    data: ProductionDeviceBody
  ) => {
    return postProductionDeviceData(`/api/organization/${+orgId!}/production_device_draft/${deviceId}`, submit, data)
  }

  export const createApprovedProductionDevice = (orgId: number, data: ProductionDeviceBody) => {
    return postProductionDeviceData(`/api/organization/${orgId}/production_device`, undefined, data)
  }

  export const updateApprovedProductionDevice = (orgId: number, deviceId: number, data: ProductionDeviceBody) => {
    return postProductionDeviceData(`/api/organization/${orgId}/production_device/${deviceId}`, undefined, data)
  }

  export const updateProductionInfoNote = (transferId: number, note: string) => {
    return api.updateProductionInfoNote(transferId, { note: note })
  }

  export function postProductionDeviceData(
    url: string,
    submit: boolean | undefined,
    data: ProductionDeviceBody
  ): Promise<AxiosResponse<ProductionDeviceViewDto>> {
    const formData = new FormData()
    if (data.poa) {
      formData.append('poa', data.poa)
    }
    data.files?.forEach((file) => formData.append('files', file))
    formData.append('dto', new Blob([JSON.stringify(data.dto)], { type: 'application/json' }))
    return axios.post(url, formData, {
      headers: { 'Content-Type': undefined },
      params: { submit: submit }
    })
  }

  export function getSignedDocumentBatches(organizationId: number | null) {
    return api.getSignedDocumentBatches(organizationId ?? 0)
  }
}
