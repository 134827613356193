import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { Autocomplete, Box, Chip, IconButton, InputLabel, TextField } from '@mui/material'
import { Clear } from '@mui/icons-material'
import Checkbox from '@mui/material/Checkbox'
import { CustomPopper } from '../custom-popper/CustomPopper'

interface IEcertAutoCompleteMultiSelectProps<T> {
  id: string
  label: string
  selectableItems: T[]
  handleChange: (selectedItems: T[]) => void
  infoPoppers?: string[]
  getOptionLabel?: (item: T) => string
  value?: T[]
}

export const EcertAutoCompleteMultiSelect = <T,>({
  id,
  label,
  selectableItems,
  handleChange,
  infoPoppers,
  getOptionLabel = (item: T) => `${item}`,
  value
}: IEcertAutoCompleteMultiSelectProps<T>) => {
  const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
  const checkedIcon = <CheckBoxIcon fontSize="small" />

  const EcertAutocompleteChip = (props) => {
    return (
      <Chip
        {...props}
        deleteIcon={
          <IconButton aria-label={`remove-${props.label}`}>
            <Clear />
          </IconButton>
        }
      />
    )
  }

  return (
    <Autocomplete
      multiple
      options={selectableItems}
      disableCloseOnSelect
      getOptionLabel={getOptionLabel}
      onChange={(event, values) => handleChange(values)}
      value={value}
      renderOption={(props, option, { selected }) => {
        if (infoPoppers && infoPoppers.length > 0) {
          const itemIndex = selectableItems.indexOf(option)

          return (
            <CustomPopper key={getOptionLabel(option)} text={infoPoppers[itemIndex]} placement="right">
              <Box component="li" {...props}>
                <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
                {getOptionLabel(option)}
              </Box>
            </CustomPopper>
          )
        } else {
          return (
            <Box {...props} component="li" key={`${getOptionLabel(option)}`} >
              <Checkbox icon={icon} checkedIcon={checkedIcon} style={{ marginRight: 8 }} checked={selected} />
              {getOptionLabel(option)}
            </Box>
          )
        }
      }}
      style={{ maxWidth: 400, minWidth: 200 }}
      renderInput={(params) => (
        <>
          <InputLabel htmlFor={id}>{label}</InputLabel>
          <TextField {...params} aria-live="polite" id={id} />
        </>
      )}
      renderTags={(tagValue, getTagProps) => {
        return tagValue.map((option, index) => (
          <EcertAutocompleteChip {...getTagProps({ index })} label={getOptionLabel(option)} key={index} />
        ))
      }}
    />
  )
}
