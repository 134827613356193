import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { FormControlLabel, InputLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { Translation, useTranslation } from 'react-i18next'
import { EcertDateRangePicker } from '../../../features/forms/date-range-picker/EcertDateRangePicker'
import { useDeferredValue, useEffect, useState } from 'react'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { ECERT_API } from '../../../services/ecert-api'
import {
  ExternalTransferQuery,
  ExternalTransferQueryStatus,
  ExternalTransferReportItemDto,
  ResultPageExternalTransferReportItemDto
} from '../../../api/types'
import { parseDate } from '../../../utils/utils'
import { Dayjs } from 'dayjs'
import { InfoPopper } from '../../../features/info-popper/InfoPopper'
import DownloadButton from '../../../features/buttons/DownloadButton'
import { OpenTransactionButton } from '../../../features/buttons/OpenTransactionButton'
import StyleGuide from '../../../styles/StyleGuide'
import { DownloadOutlined } from '@mui/icons-material'
import { Paginator } from '../../../features/paginator/Paginator'
import { isAdmin } from '../../../utils/permission-utils'
import { useLoginUser } from '../../../LoginContext'
import OrganizationSelect from '../../../features/organization-select/OrganizationSelect'
import { useOrganizations } from '../../../features/store-slices/organizationsSlice'

export const ExternalTransfer = () => {
  const { t } = useTranslation()
  const statusOptions: ExternalTransferQueryStatus[] = [
    ExternalTransferQueryStatus.ANY,
    ExternalTransferQueryStatus.IN_PROGRESS,
    ExternalTransferQueryStatus.SUCCESS,
    ExternalTransferQueryStatus.ERROR
  ]
  const [selectedStatus, setSelectedStatus] = useState<ExternalTransferQueryStatus>(ExternalTransferQueryStatus.ANY)
  const selectedStatusDeferred = useDeferredValue(selectedStatus)
  const [externalTransferReport, setExternalTransferReport] = useState<ResultPageExternalTransferReportItemDto | null>(
    null
  )
  const [currentPage, setCurrentPage] = useState(1)
  const currentPageDeferred = useDeferredValue(currentPage)
  const [fromTime, setFromTime] = useState<Dayjs | null>(null)
  const fromTimeDeferred = useDeferredValue(fromTime)
  const [toTime, setToTime] = useState<Dayjs | null>(null)
  const toTimeDeferred = useDeferredValue(toTime)
  const loginUser = useLoginUser()
  const [organizations, fetchOrganizations] = useOrganizations(false)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number>(0)

  const columns: IColumn[] = [
    {
      label: t('externalTransferReport.messageId'),
      accessor: 'messageId',
      sortable: false,
      role: 'rowheader'
    },
    {
      label: t('externalTransferReport.transactionId'),
      accessor: (obj: ExternalTransferReportItemDto) => obj.transactionId,
      sortable: false
    },
    {
      label: t('externalTransferReport.eventTime'),
      accessor: (obj: ExternalTransferReportItemDto) => parseDate(obj.createdAt!).format('DD.MM.YYYY HH:mm'),
      sortable: false
    },
    {
      label: t('externalTransferReport.state'),
      accessor: (obj: ExternalTransferReportItemDto) => (
        <FlexRow gap="5px" alignItems="flex-end">
          <Translation>{(t) => <>{t(`transaction.externalTransferStateType.${obj.state}`)}</>}</Translation>
          <InfoPopper
            text=""
            translationElement={
              <Translation>{(t) => <>{t(`transaction.externalTransferStateDescription.${obj.state}`)}</>}</Translation>
            }
          />
        </FlexRow>
      ),
      sortable: false
    },
    {
      label: t('externalTransferReport.info'),
      accessor: (obj: ExternalTransferReportItemDto) => (
        <FlexRow gap="5px">
          <DownloadButton
            displayLoadingProgress={false}
            fileUrl={ECERT_API.FILE_DOWNLOAD_URLS.EXTERNAL_TRANSFER(`${obj.id!}`)}
            fileName={`externalTransfer${obj.id!}.xml`}
          >
            <DownloadOutlined sx={{ color: StyleGuide.constants.COLOR_BRAND }} />
          </DownloadButton>
          <OpenTransactionButton
            organizationId={obj.organizationId!}
            accountId={obj.accountId!}
            transactionId={obj.transactionId!}
          />
        </FlexRow>
      ),
      sortable: false
    }
  ]

  useEffect(() => {
    document.title = `Ecert - ${t('title.externalTransferReport')}`

    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (isAdmin(loginUser)) {
      fetchOrganizations()
    }
  }, [loginUser, fetchOrganizations])

  useEffect(() => {
    setExternalTransferReport((prevState) => {
      const newState = { ...prevState }
      newState.content = undefined
      return newState
    })

    const externalTransferQuery: ExternalTransferQuery = {
      fromTime: fromTimeDeferred?.format('YYYY-MM-DD'),
      toTime: toTimeDeferred?.format('YYYY-MM-DD'),
      status: selectedStatusDeferred,
      page: currentPageDeferred - 1
    }

    ECERT_API.searchExternalTransfer(
      externalTransferQuery,
      isAdmin(loginUser) ? selectedOrganizationId : loginUser.organizationId!
    ).then((response) => {
      setExternalTransferReport(response.data)
    })
  }, [currentPageDeferred, selectedStatusDeferred, fromTimeDeferred, toTimeDeferred, loginUser, selectedOrganizationId])

  const getTranslationForOption = (opt: ExternalTransferQueryStatus) => {
    switch (opt) {
      case ExternalTransferQueryStatus.ANY:
        return t('common.statusAny')
      case ExternalTransferQueryStatus.IN_PROGRESS:
        return t('common.statusInProgress')
      case ExternalTransferQueryStatus.SUCCESS:
        return t('common.statusSuccess')
      case ExternalTransferQueryStatus.ERROR:
        return t('common.statusError')
    }
  }

  const handleFromTimeChange = (fromTime: Dayjs) => {
    setFromTime(fromTime)
  }

  const handleToTimeChange = (toTime: Dayjs) => {
    setToTime(toTime)
  }

  return (
    <FlexColumn gap="20px">
      <Typography id="title-report-external-transfers" variant="h1">
        {t('externalTransferReport.title')}
      </Typography>
      <FlexRow gap="20px" alignItems="flex-end">
        <EcertDateRangePicker
          label={t('externalTransferReport.time')}
          defaultValue={{}}
          onUpdateStart={handleFromTimeChange}
          onUpdateEnd={handleToTimeChange}
          clearable={true}
        />
        <FlexColumn>
          <InputLabel>{t('externalTransferReport.status')}</InputLabel>
          <RadioGroup
            row
            value={selectedStatus}
            onChange={(event, value) => setSelectedStatus(value as ExternalTransferQueryStatus)}
          >
            {statusOptions.map((opt) => (
              <FormControlLabel key={opt} value={opt} control={<Radio />} label={getTranslationForOption(opt)} />
            ))}
          </RadioGroup>
        </FlexColumn>
        {isAdmin(loginUser) && (
          <OrganizationSelect
            value={organizations.find((org) => org.id === selectedOrganizationId) ?? null}
            organizations={organizations}
            onSelect={(organization) => {
              setSelectedOrganizationId(organization ? organization.id! : 0)
            }}
          />
        )}
      </FlexRow>

      {externalTransferReport && (
        <>
          <EcertTable
            ariaDescribedBy="title-report-external-transfers"
            data={externalTransferReport.content}
            columns={columns}
          />

          <Paginator
            currentPage={currentPage}
            setCurrentPage={setCurrentPage}
            maxPages={externalTransferReport.totalPages ?? 0}
          />
        </>
      )}
    </FlexColumn>
  )
}
