import { FlexColumn, FlexRow } from '../../../styles/containers/FlexContainers'
import { Box, FormControlLabel, InputLabel, Radio, RadioGroup, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EcertDateRangePicker } from '../../../features/forms/date-range-picker/EcertDateRangePicker'
import { Dayjs } from 'dayjs'
import { useDeferredValue, useEffect, useState } from 'react'
import { ECERT_API } from '../../../services/ecert-api'
import {
  LogEntryDto,
  LogEntryDtoOperation,
  LogEntryQuery,
  LogEntryQueryStatus,
  ResultPageLogEntryDto,
  UserListItemDto
} from '../../../api/types'
import OrganizationSelect from '../../../features/organization-select/OrganizationSelect'
import { UserSelect } from '../../../features/user-select/UserSelect'
import EcertTable, { IColumn } from '../../../features/table/EcertTable'
import { Paginator } from '../../../features/paginator/Paginator'
import { parseDate } from '../../../utils/utils'
import { EcertRoutes } from '../../../ecert-routes'
import Check from '@mui/icons-material/Check'
import Clear from '@mui/icons-material/Clear'
import StyleGuide from '../../../styles/StyleGuide'
import { canViewOrganizations } from '../../../utils/permission-utils'
import { useLoginUser } from '../../../LoginContext'
import { useOrganizations } from '../../../features/store-slices/organizationsSlice'
import { useUsers } from '../../../features/store-slices/usersSlice'

export const EventLog = () => {
  const { t } = useTranslation()
  const loginUser = useLoginUser()
  const [fromTime, setFromTime] = useState<Dayjs | null>(null)
  const fromTimeDeferred = useDeferredValue(fromTime)
  const [toTime, setToTime] = useState<Dayjs | null>(null)
  const toTimeDeferred = useDeferredValue(toTime)
  const [organizations, fetchOrganizations] = useOrganizations(false)
  const [selectedOrganizationId, setSelectedOrganizationId] = useState<number>(loginUser.organizationId!)
  const selectedOrganizationIdDeferred = useDeferredValue(selectedOrganizationId)
  const [users] = useUsers(true, true, selectedOrganizationId === 0 ? undefined : selectedOrganizationId)
  const [selectedUser, setSelectedUser] = useState<UserListItemDto | null>(null)
  const selectedUserDeferred = useDeferredValue(selectedUser)
  const statusOptions: LogEntryQueryStatus[] = [
    LogEntryQueryStatus.ANY,
    LogEntryQueryStatus.SUCCESS,
    LogEntryQueryStatus.ERROR
  ]
  const [selectedStatus, setSelectedStatus] = useState<LogEntryQueryStatus>(LogEntryQueryStatus.ANY)
  const selectedStatusDeferred = useDeferredValue(selectedStatus)
  const [logEntry, setLogEntry] = useState<ResultPageLogEntryDto>()
  const [currentPage, setCurrentPage] = useState(1)
  const columns: IColumn[] = [
    {
      label: t('eventLog.time'),
      accessor: (obj: LogEntryDto) => parseDate(obj.time!).format('DD.MM.YYYY HH:mm'),
      sortable: false
    },
    {
      label: t('eventLog.user'),
      accessor: (obj: LogEntryDto) => obj.userFullName,
      sortable: false
    },
    {
      label: t('eventLog.userOrganization'),
      accessor: (obj: LogEntryDto) => obj.userOrganizationName,
      sortable: false
    },
    {
      label: t('eventLog.eventDesc'),
      accessor: (obj: LogEntryDto) => (
        <FlexRow gap="8px">
          <Typography>{t(`eventLog.event.${obj.target}_${obj.operation}`)}</Typography>
          {obj.error ? (
            <Clear sx={{ color: StyleGuide.constants.COLOR_RED_30 }} />
          ) : (
            <Check sx={{ color: StyleGuide.constants.COLOR_SUCCESS }} />
          )}
        </FlexRow>
      ),
      sortable: false,
      role: 'rowheader'
    },
    {
      label: t('eventLog.targetEntity'),
      accessor: (obj: LogEntryDto) => {
        let text = ''
        try {
          text = getTargetText(obj)
        } catch (error) {
          text = ''
        }
        return text
      },
      accessibilityHrefFn: (obj: LogEntryDto) => {
        let link = ''
        try {
          link = getTargetLink(obj)
        } catch (error) {
          link = ''
        }
        return link
      },
      sortable: false
    }
  ]

  useEffect(() => {
    document.title = `Ecert - ${t('title.eventLog')}`
  }, [t])

  useEffect(() => {
    if (canViewOrganizations(loginUser)) {
      fetchOrganizations()
    }
  }, [loginUser, fetchOrganizations])

  useEffect(() => {
    reloadData(1)
    // eslint-disable-next-line
  }, [
    loginUser,
    fromTimeDeferred,
    toTimeDeferred,
    selectedStatusDeferred,
    selectedOrganizationIdDeferred,
    selectedUserDeferred
  ])

  const reloadData = (page: number) => {
    setLogEntry((prevState) => {
      const newState = { ...prevState }
      newState.content = undefined
      return newState
    })
    const logEntryQuery: LogEntryQuery = {
      fromTime: fromTimeDeferred?.format('YYYY-MM-DD'),
      toTime: toTimeDeferred?.format('YYYY-MM-DD'),
      page: page - 1,
      status: selectedStatusDeferred,
      userId: selectedUserDeferred?.id
    }
    ECERT_API.searchEventLog(selectedOrganizationId, logEntryQuery).then((response) => {
      if (page === 1) {
        setCurrentPage(1)
      }
      setLogEntry(response.data)
    })
  }

  const handleFromTimeChange = (fromTime: Dayjs) => {
    setFromTime(fromTime)
  }

  const getTargetText = (entry: LogEntryDto) => {
    const text = t(`eventLog.linkText.${entry.target}`)
    const inputJSON = entry.input ? JSON.parse(entry.input) : ''
    const outputJSON = entry.output ? JSON.parse(entry.output) : ''

    switch (entry.target) {
      case 'ORGANIZATION':
        return entry.output ? `${text}: ${outputJSON?.['name']}` : ''
      case 'ORGANIZATION_MAIN_USER':
        return `${text}: ${entry.organizationName}`
      case 'ORGANIZATION_LOCKED_STATE':
        return `${text}: ${outputJSON?.['entityName']}`
      case 'CANCELLATION':
        return entry.input && entry.operation === LogEntryDtoOperation.CREATE
          ? `${text}: ${inputJSON['sourceAccountId']}`
          : ''
      case 'CANCELLATION_ROLLBACK':
        return `${text}: ${outputJSON['accountId']}`
      case 'EXTERNAL_TRANSFER':
      case 'CORRECTION':
      case 'ORGANIZATION_TRANSFER':
      case 'DOMESTIC_TRANSFER':
        return entry.input ? `${text}: ${inputJSON['sourceAccountId']}` : ''
      case 'ACCOUNT':
        return `${text}: ${outputJSON?.['name']}`
      case 'ACCOUNT_LOCKED_STATE':
      case 'USER_LOCKED_STATE':
        return `${text}: ${outputJSON?.['entityName']}`
      case 'PRODUCTION_DEVICE_STATUS':
      case 'PRODUCTION_DEVICE':
      case 'PRODUCTION_DEVICE_VERSION':
        return entry.output ? `${text}: ${outputJSON?.['name']}` : ''
      case 'PRODUCTION_DEVICE_FUEL_DISTRIBUTION':
        return entry.input ? `${text}: ${inputJSON?.['productionDeviceName']}` : ''
      case 'PRODUCTION_DEVICE_LOCKED_STATE':
        return entry.output ? `${text}: ${outputJSON?.['entityName']}` : ''
      case 'USER_LOGIN':
        return `${text}: ${entry.userFullName}`
      case 'USER':
      case 'USER_SETTING':
        return entry.output ? `${text}: ${outputJSON?.['firstName']} ${outputJSON?.['lastName']}` : ''
      case 'AUTOMATIC_TRANSFER':
        return entry.input ? `${text}: ${inputJSON['accountId']}` : ''
      case 'EXPORT_INVOICES_FOR_BILLING':
      case 'USER_PASSWORD':
      case 'PUBLISH_INVOICE_PERIOD':
      case 'ISSUE_CERTIFICATES':
      case 'PRODUCTION_INFO_TRANSFER':
        return ''
      default:
        return ''
    }
  }

  const getTargetLink = (entry: LogEntryDto): string => {
    const inputJSON = entry.input ? JSON.parse(entry.input) : ''
    const outputJSON = entry.output ? JSON.parse(entry.output) : ''

    switch (entry.target) {
      case 'ORGANIZATION':
        return entry.output ? EcertRoutes.organization(outputJSON['id']) : ''
      case 'ORGANIZATION_MAIN_USER':
      case 'ORGANIZATION_LOCKED_STATE':
        return EcertRoutes.organization(entry.organizationId)
      case 'CANCELLATION':
        return entry.input && entry.operation === LogEntryDtoOperation.CREATE
          ? EcertRoutes.organizationAccount(entry.organizationId, inputJSON['sourceAccountId'])
          : ''
      case 'CANCELLATION_ROLLBACK':
        return EcertRoutes.organizationAccount(outputJSON['organizationId'], outputJSON['accountId'])
      case 'EXTERNAL_TRANSFER':
      case 'CORRECTION':
      case 'ORGANIZATION_TRANSFER':
      case 'DOMESTIC_TRANSFER':
        return entry.input ? EcertRoutes.organizationAccount(entry.organizationId, inputJSON['sourceAccountId']) : ''
      case 'ACCOUNT':
        return EcertRoutes.organizationAccount(entry.organizationId, outputJSON?.['id'])
      case 'ACCOUNT_LOCKED_STATE':
      case 'USER_LOCKED_STATE':
        return EcertRoutes.organizationAccount(entry.organizationId, outputJSON?.['entityId'])
      case 'PRODUCTION_DEVICE_STATUS':
      case 'PRODUCTION_DEVICE':
      case 'PRODUCTION_DEVICE_VERSION':
        return entry.output ? EcertRoutes.productionDevice(entry.organizationId, outputJSON?.['id']) : ''
      case 'PRODUCTION_DEVICE_FUEL_DISTRIBUTION':
        return entry.input ? EcertRoutes.productionDevice(entry.organizationId, inputJSON?.['productionDeviceId']) : ''
      case 'PRODUCTION_DEVICE_LOCKED_STATE':
        return entry.output ? EcertRoutes.productionDevice(entry.organizationId, outputJSON?.['entityId']) : ''
      case 'USER_LOGIN':
        return EcertRoutes.organizationUser(entry.userOrganizationId, entry.userid)
      case 'USER':
      case 'USER_SETTING':
        return entry.output
          ? EcertRoutes.organizationUser(entry.organizationId || entry.userOrganizationId, outputJSON?.['id'])
          : ''
      case 'AUTOMATIC_TRANSFER':
        return entry.input ? EcertRoutes.organizationAccount(entry.organizationId, inputJSON['accountId']) : ''
      case 'EXPORT_INVOICES_FOR_BILLING':
      case 'USER_PASSWORD':
      case 'PUBLISH_INVOICE_PERIOD':
      case 'ISSUE_CERTIFICATES':
      case 'PRODUCTION_INFO_TRANSFER':
        return ''
      default:
        return ''
    }
  }

  const handleToTimeChange = (toTime: Dayjs) => {
    setToTime(toTime)
  }

  const getTranslationForOption = (opt: LogEntryQueryStatus) => {
    switch (opt) {
      case LogEntryQueryStatus.ANY:
        return t('common.statusAny')
      case LogEntryQueryStatus.SUCCESS:
        return t('common.statusSuccess')
      case LogEntryQueryStatus.ERROR:
        return t('common.statusError')
    }
  }

  return (
    <FlexColumn gap="20px">
      <Typography id="title-report-event-log" variant="h1">
        {t('eventLog.title')}
      </Typography>
      <FlexRow gap="20px" justifyContent="stretch" alignItems="flex-end">
        <Box>
          <EcertDateRangePicker
            clearable={true}
            label={t('eventLog.time')}
            defaultValue={{}}
            onUpdateStart={handleFromTimeChange}
            onUpdateEnd={handleToTimeChange}
          />
        </Box>
        <FlexColumn>
          <InputLabel>{t('eventLog.status')}</InputLabel>
          <RadioGroup
            row
            value={selectedStatus}
            onChange={(event, value) => setSelectedStatus(value as LogEntryQueryStatus)}
          >
            {statusOptions.map((opt) => (
              <FormControlLabel key={opt} value={opt} control={<Radio />} label={getTranslationForOption(opt)} />
            ))}
          </RadioGroup>
        </FlexColumn>
        {canViewOrganizations(loginUser) && organizations.length > 0 && (
          <Box>
            <OrganizationSelect
              value={organizations.find((org) => org.id === selectedOrganizationId) ?? null}
              organizations={organizations}
              onSelect={(organization) => {
                setSelectedUser(null)
                setSelectedOrganizationId(organization ? organization.id! : 0)
              }}
            />
          </Box>
        )}
        {users && (
          <UserSelect
            displayOrganization={selectedOrganizationId === 0}
            users={users}
            selectedUser={selectedUser}
            onSelect={setSelectedUser}
          />
        )}
      </FlexRow>

      {logEntry && (
        <>
          <EcertTable ariaDescribedBy="title-report-event-log" data={logEntry.content} columns={columns} />
          <Paginator
            currentPage={currentPage}
            setCurrentPage={(currentPage) => {
              setCurrentPage(currentPage)
              reloadData(currentPage)
            }}
            maxPages={logEntry.totalPages ?? 0}
          />
        </>
      )}
    </FlexColumn>
  )
}
